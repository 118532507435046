<template>
  <v-row>
      <v-col cols="12">
          NOT FOUND PAGE
      </v-col>
  </v-row>
</template>

<script>

  export default {
    name: '404',
  }
</script>
